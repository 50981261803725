import React from "react";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts";
import { Stack } from "@mui/material";

// this component is a pie chart that is used to display the market share of each carrier
const PieChartModelAgentInfo = (props) => {
  const { pieData } = props;
  // this returns a div containing the pie, its legend, and its tooltip
  if (pieData.length !== 0 && pieData !== undefined) {
    return (
      <div style={{ width: 400, height: 450 }}>
        <Stack
          direction={{ xs: "column", md: "row" }}
          alignItems={{ xs: "flex-start", md: "center" }}
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <PieChart
            series={[
              {
                type: "pie",
                data: pieData.map(item => ({ ...item, value: Number(item.value) })),
                arcLabel: (item) => `${item.value}`,
                innerRadius: 120,
                outerRadius: 215,
                paddingAngle: 5,
                cornerRadius: 5,
                startAngle: -90,
                endAngle: 360,
                margin: { top: 100, bottom: 25, left: 25, right: 25 },
                alignItems: "center",
                highlightScope: { faded: 'global', highlighted: 'item' },
                faded: { additionalRadius: -30, color: 'gray' },
              },
            ]}
            sx={{
              mt: -20,
              [`& .${pieArcLabelClasses.root}`]: {
                fill: "black",
                fontWeight: "bold",
                fontSize: "15px",
              },
            }}
            width={600}
            height={600}
            margin={{ top: 100, bottom: 50, left: 25, right: 25 }}
            slotProps={{
              legend: {
                direction: "row",
                position: { vertical: "top", horizontal: "middle" },
                padding: 0,     
                labelStyle: { fontSize: '20px' }, 
              },
              layout: {
                padding: {
                  top: -10, // moves the whole chart up by 10 pixels
                },
              },
            }}
          />
        </Stack>
      </div>
    );
  }
  return null;
};

export default PieChartModelAgentInfo;
