import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import FormControl from "@mui/material/FormControl/FormControl";
import Select from "@mui/material/Select/Select";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentProduction } from "../../store/actions/production/CurrentProduction";
import { setCriteria } from "../../store/actions/production/productionActions";
const DropDown = (props) => {
  const dispatch = useDispatch();
  const { setYear, data } = props;
  const [selectedValue, setSelectedValue] = useState(maxYear(data));
  const productionData = useSelector((state) => state.currentProduction);
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    setYear(event.target.value);
    let agencyId = sessionStorage.getItem("agencyId");
    let agentId = sessionStorage.getItem("agentId");
    //Set Criteria
    setCriteria({
      selectedAgency: agencyId,
      selectedPlanYear: event.target.value,
    });
    //Set Current Production
    if (productionData && !productionData.production[event.target.value]) {
      dispatch(setCurrentProduction(agencyId, agentId, event.target.value));
    }
  };
  return (
    <FormControl>
      <label htmlFor="year-select">Select a Year</label>
      <Select
        id="year-select"
        value={selectedValue}
        onChange={handleChange}
        size="small"
      >
        {years(data).map((year, index) =>
          currentYear - 2 <= year ? (
            <MenuItem key={index} value={year}>
              {year}
            </MenuItem>
          ) : null
        )}
      </Select>
    </FormControl>
  );
};
const years = (data) => {
  const temp = [];
  const salesYearList = data.map((item) => item.salesYear);
  if (salesYearList.length > 0) {
    salesYearList.forEach((item) => {
      if (!temp.includes(item)) {
        temp.push(item);
      }
    });
    return temp.sort((a, b) => b - a);
  }
  return temp;
};
const maxYear = (data) => {
  const salesYearList = data.map((item) => item.salesYear);
  if (salesYearList.length > 0) {
    return Math.max(...salesYearList);
  }
  return 0;
};
const currentYear = new Date().getFullYear();

export default DropDown;
