import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import classes from "../../../../../components/DataGridTemplate/DataGridTemplate.module.css";
import StripedDataGridTemplate from "../../../../DataGridTemplate/StripedDataGridTemplate";
import AgentReadyToSellProducts from "../agentReadyToSellProducts/AgentReadyToSellProducts";
import AgentsReadyToSellColumns from "./AgentsReadyToSellColumns";
import  AgentsReadyToSellData from "./AgentsReadyToSellData";
import _ from 'lodash';

const AgentsReadyToSellInfo = (props) => {
  const { agentId } = props;
  const data = useSelector((state) => state.agentsReadyToSellInfo.data);
  const [filterModel, setFilterModel] = useState(
    useSelector((state) => state.agentsReadyToSellInfo.filterModel)
  );

  const isFetchingRTSInfo = useSelector(
    (state) => state.agentsReadyToSellInfo.isFetchingRTSInfo
  );
  const isLoading = false;

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 5,
    page: 0,
  });

  const columns = AgentsReadyToSellColumns();

  const filteredData = data.filter(
    (row) => row.agentId === agentId && row.writingNumber !== "No RTS Rows"
  );
  
  const uniqueRows = AgentsReadyToSellData({
    data: filteredData,
    agentId: agentId,
  });

  /// The getDetailPanelHeight function is used to get the height of the detail panel.
  const getDetailPanelHeight = useCallback(() => 250, []);
  /// The getContent function is used to get the content for the detail panel.
  const getContent = (row) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <AgentReadyToSellProducts
          data={filteredData.filter(
            (r) =>
              r.carrierName === row.carrierName && r.planYear === row.planYear
          )}
        />
      </div>
    );
  };

  const sxToolBarContainer = { fontSize: 12, paddingBottom: 1 };
  const sxToolBarColumnsButton = {
    color: "black",
    backgroundColor: "whitesmoke",
  };

  /// Custom toolbar for the DataGrid
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer sx={sxToolBarContainer}>
        <GridToolbarFilterButton sx={sxToolBarColumnsButton} />
        <GridToolbarExport sx={sxToolBarColumnsButton} />
      </GridToolbarContainer>
    );
  };

  const StripedDataGrid = StripedDataGridTemplate();

  const sxBox = {
    height: "auto",
    width: "100%",
    "& .super-app-theme--header": {
      backgroundColor: "rgba(0, 255, 119, 0.8)",
    },
    "& .super-app.true": {
      color: "green",
      fontWeight: "600",
    },
    "& .super-app.false": {
      color: "red",
      fontWeight: "600",
    },
  };

  const getRowClassName = (params) => {
    return params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd";
  };

  const debouncedSetFilterModel = _.debounce((newFilterModel) => {
    setFilterModel(newFilterModel);
  }, 1000); // 1000ms delay

  return (
    <>
      {isFetchingRTSInfo && <h5>Loading...</h5>}
      {!isFetchingRTSInfo && (uniqueRows || []).length === 0 && (
        <h5>No Data Available</h5>
      )}
      {!isFetchingRTSInfo && (uniqueRows || []).length > 0 && (
        /// Return the DataGrid
        <div className={classes.myClass}>
          <Box sx={sxBox}>
            <StripedDataGrid
              slots={{ toolbar: CustomToolbar }}
              loading={isLoading}
              rows={(uniqueRows || []).sort((a, b) =>
                (a.carrierName || "").localeCompare(b.carrierName || "")
              )}
              columns={columns}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              pageSizeOptions={[5, 10, 25]}
              pagination={true}
              disableRowSelectionOnClick
              getRowClassName={getRowClassName}
              getDetailPanelHeight={getDetailPanelHeight}
              getDetailPanelContent={({ row }) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                  }}
                >
                  {getContent(row)}
                </div>
              )}
              filterModel={filterModel}
              onFilterModelChange={(newFilterModel) => {
                debouncedSetFilterModel(newFilterModel);
              }}
              density="compact"
            />
          </Box>
        </div>
      )}
    </>
  );
};

export default AgentsReadyToSellInfo;
