import {
  CLEAR_AGENT_READY_TO_SELL_INFO,
  SET_AGENT_READY_TO_SELL_INFO,
  RTS_INFO_LOADING,
  SET_AGENT_READY_TO_SELL_INFO_FILTER_MODEL,
  CLEAR_AGENT_READY_TO_SELL_INFO_FILTER_MODEL,
} from "../../constants/action-types";
import { getAgentsReadyToSellInfo } from "../../../services/GraphQl/BDSWebService";

/* redux-thunk implementation */
export const fetchAgentReadyToSellInfo = (agencyId) => {
  return async (dispatch) => {
    dispatch(setRTSInfoLoading(true));
    await getAgentsReadyToSellInfo(agencyId)
      .then(async (res) => {
        if (res) {
          dispatch(setAgentsReadyToSellInfo(res));
        }
      })
      .then(() => {
        dispatch(setRTSInfoLoading(false));
      });
  };
};

export const clearAgentsReadyToSellInfo = () => {
  return (dispatch) => {
    dispatch(clearAgentsReadyToSellInfoAction([]));
  };
};

/* redux action */
const setAgentsReadyToSellInfo = (data) => {
  return {
    type: SET_AGENT_READY_TO_SELL_INFO,
    payload: data,
  };
};

export const setRTSInfoLoading = (data) => {
  return (dispatch) => {
    dispatch({
      type: RTS_INFO_LOADING,
      payload: data,
    });
  };
};

/* redux action */
const clearAgentsReadyToSellInfoAction = (data) => {
  return {
    type: CLEAR_AGENT_READY_TO_SELL_INFO,
    payload: data,
  };
};

export const setAgentsReadyToSellInfoFilterModel = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_AGENT_READY_TO_SELL_INFO_FILTER_MODEL,
      payload: data,
    });
  };
};

export const clearAgentsReadyToSellInfoFilterModel = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_AGENT_READY_TO_SELL_INFO_FILTER_MODEL,
    });
  };
};
