import {
  CLEAR_AGENT_READY_TO_SELL_INFO,
  SET_AGENT_READY_TO_SELL_INFO,
  RTS_INFO_LOADING,
  SET_AGENT_READY_TO_SELL_INFO_FILTER_MODEL,
  CLEAR_AGENT_READY_TO_SELL_INFO_FILTER_MODEL,
} from "../../constants/action-types";

const initState = {
  data: [],
  filterModel: {
    items: [],
  },
  isFetchingRTSInfo: false,
};

export const agentsReadyToSellInfo = (state = initState, { type, payload }) => {
  switch (type) {
    case SET_AGENT_READY_TO_SELL_INFO:
      return { ...state, data: payload };
    case CLEAR_AGENT_READY_TO_SELL_INFO:
      return { ...state, data: [] };
    case RTS_INFO_LOADING:
      return { ...state, isFetchingRTSInfo: payload };
    case SET_AGENT_READY_TO_SELL_INFO_FILTER_MODEL:
      return { ...state, filterModel: payload };
    case CLEAR_AGENT_READY_TO_SELL_INFO_FILTER_MODEL:
      return {
        ...state,
        filterModel: {
          items: [],
        },
      };
    default:
      return state;
  }
};
