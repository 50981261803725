import {
  SET_PLAN_PRODUCTION,
  SET_PLAN_PRODUCTION_ERROR,
  CLEAR_PLAN_PRODUCTION,
  PLAN_PRODUCTION_LOADING,
} from "../../constants/action-types";
import {
  getCurrentProductionYear,
  getAvailablePlanYears,
} from "../../../services/GraphQl/BDSWebService";
import ProductionLoader from "../../../services/webservices/ProductionLoader";

/* redux-thunk implementation */
export const setPlanChange = (agencyId, agentId, year) => {
  return async (dispatch, getState) => {
    dispatch({
      type: PLAN_PRODUCTION_LOADING,
      payload: true,
    });

    // let currentProductionYear = sessionStorage.getItem("currentProductionYear");
    // if (!currentProductionYear) {
    //   currentProductionYear = await getCurrentProductionYear();
    //   sessionStorage.setItem("currentProductionYear", currentProductionYear);
    // }
    // let availablePlanYears = sessionStorage.getItem("availablePlanYears");
    // if (!availablePlanYears) {
    //   availablePlanYears = await getAvailablePlanYears();
    //   sessionStorage.setItem("availablePlanYears", availablePlanYears);
    // } else {
    //   availablePlanYears = availablePlanYears.split(",");
    // }
    // if (!year) {
    //   year = currentProductionYear;
    // }

    // let AllPlanChangeResults = [];

    // for (const element of availablePlanYears) {
    //   let PlanChangeResult = await ProductionLoader.loadPlanChange(
    //     agencyId,
    //     element.toString(),
    //     null,
    //     agentId
    //   );
    //   let PlanChangeResultData =
    //     PlanChangeResult.data?.getProductionStatisticsHelper;
    //   if (
    //     Array.isArray(PlanChangeResultData) &&
    //     PlanChangeResultData.length > 0
    //   ) {
    //     AllPlanChangeResults.push(...PlanChangeResultData);
    //   }
    // }

    try {
      // dispatch(
      //   setPlanChangeResults({
      //     currentYear: currentProductionYear,
      //     availablePlanYears: availablePlanYears,
      //     production: AllPlanChangeResults,
      //   })
      // );
    } catch (error) {
      dispatch({
        type: SET_PLAN_PRODUCTION_ERROR,
        payload: error,
      });
    } finally {
      dispatch({
        type: PLAN_PRODUCTION_LOADING,
        payload: false,
      });
    }
  };
};

export const clearPlanChange = () => {
  return (dispatch) => {
    dispatch(clearPlanChangeResults());
  };
};

/* redux action */
const setPlanChangeResults = (data) => {
  return {
    type: SET_PLAN_PRODUCTION,
    payload: data,
  };
};

const clearPlanChangeResults = (data) => {
  return {
    type: CLEAR_PLAN_PRODUCTION,
    payload: data,
  };
};
