import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import classes from "../../DataGridTemplate/DataGridTemplate.module.css";
import StripedDataGridTemplate from "../../DataGridTemplate/StripedDataGridTemplate";
import { setFilteredPlanChangeTotals } from "../../../store/actions/plan-change/filteredPlanChangeTotals";
import PlanChangesColumns from "./PlanChangesColumns";
import PlanChangesData from "./PlanChangesData";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  gridFilteredSortedRowIdsSelector,
  useGridApiRef,
} from "@mui/x-data-grid-pro";

const PlanChangeProductionTable = (props) => {
  const data = useSelector((state) => state.planChange.production);
  const isLoading = useSelector(
    (state) => state.planChange.isFetchingPlanChange
  );
  const filterModel = useSelector((state) => state.planChange.filterModel);
  const apiRef = useGridApiRef();
  const dispatch = useDispatch();
  const columns = PlanChangesColumns();
  const uniqueRows = data
    ? PlanChangesData({
        data: data,
      })
    : [];
  const sxToolBarContainer = { fontSize: 12, paddingBottom: 1 };
  const sxToolBarColumnsButton = {
    color: "black",
    backgroundColor: "whitesmoke",
  };

  /// Custom toolbar for the DataGrid
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer sx={sxToolBarContainer}>
        <GridToolbarColumnsButton sx={sxToolBarColumnsButton} />
        <GridToolbarFilterButton sx={sxToolBarColumnsButton} />
        <GridToolbarDensitySelector sx={sxToolBarColumnsButton} />
        <GridToolbarExport sx={sxToolBarColumnsButton} />
      </GridToolbarContainer>
    );
  };

  const StripedDataGrid = StripedDataGridTemplate();

  const sxBox = {
    height: 640,
    width: "auto",
    "& .super-app-theme--header": {
      backgroundColor: "rgba(27, 133, 243, 0.8)",
    },
  };

  const MemoizedDataGrid = memo(StripedDataGrid);

  return (
    <div className={classes.myClass}>
      <Box sx={sxBox}>
        <MemoizedDataGrid
          apiRef={apiRef}
          slots={{ toolbar: CustomToolbar }}
          loading={isLoading}
          rows={uniqueRows.sort((a, b) =>
            (a.agentname || "").localeCompare(b.agentname || "")
          )}
          columns={columns}
          pagination={{ rowsPerPageOptions: [25, 50, 100], pageSize: 25 }}
          disableRowSelectionOnClick
          initialState={{
            filter: { filterModel: filterModel },
          }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
          }
          onStateChange={() => {
            const filteredSortedRowIds =
              gridFilteredSortedRowIdsSelector(apiRef);
            const filteredSortedRows = filteredSortedRowIds.map((id) =>
              apiRef.current.getRow(id)
            );
            const maTotal = filteredSortedRows.reduce(
              (sum, row) => sum + parseInt(row.ma_production),
              0
            );
            const msTotal = filteredSortedRows.reduce(
              (sum, row) => sum + parseInt(row.medsupp_production),
              0
            );
            const pdpTotal = filteredSortedRows.reduce(
              (sum, row) => sum + parseInt(row.pdp_production),
              0
            );

            dispatch(
              setFilteredPlanChangeTotals({
                maTotal: maTotal,
                msTotal: msTotal,
                pdpTotal: pdpTotal,
              })
            );
          }}
        />
      </Box>
    </div>
  );
};

export default PlanChangeProductionTable;
