import React, { Fragment } from "react";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { Typography, Paper } from "@mui/material";
import ProductionOverviewDataGrid from "./ProductionOverviewDataGrid";
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  heading: {
    color: "#003760",
  },
  table: {
    padding: 10,
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 34,
    width: 3,
    backgroundColor: "grey",
  },
  paper: (props) => ({
    padding: 10,
    margin: 10,
    width: props.isSmallScreen ? "100%" : "auto",
    height: 115,
  }),
}));

const ProductionOverview = (props) => {
  const {
    currentProduction,
    currentProduction: { currentYear },
  } = props;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles({ isSmallScreen });
  let results = [];

  let ma_top = "",
    ma_total = "",
    ms_top = "",
    ms_total = "",
    productMA = "Medicare Advantage",
    productMS = "Medicare Supplement";

  const currentProductionData = currentProduction.production
    ? currentProduction.production[currentYear]
    : null;
  if (currentProduction && currentYear && currentProductionData) {
    ma_top = currentProductionData.ma_top;
    ma_total = currentProductionData.ma_total;
    ms_top = currentProductionData.ms_top;
    ms_total = currentProductionData.ms_total;

    results.push(
      {
        id: 1,
        product: productMA,
        top: ma_top,
        total: ma_total,
      },
      {
        id: 2,
        product: productMS,
        top: ms_top,
        total: ms_total,
      }
    );
  }
  return (
    <Fragment>
      <Typography color="#003760" gutterBottom variant="h5" component="h3">
        Production Overview
      </Typography>
      <Paper className={classes.paper}>
        <ProductionOverviewDataGrid tableData={results} />
      </Paper>
    </Fragment>
  );
};

export default ProductionOverview;
