import {
  SET_COMMISSION_LOSS_REPORT,
  SET_COMMISSION_LOSS_REPORT_ERROR,
  CLEAR_COMMISSION_LOSS_REPORT,
  COMMISSION_LOSS_REPORT_LOADING,
} from "../../constants/action-types";

import { getCLR } from "../../../services/GraphQl/BDSWebService";

export const setCommissionLossReport = (data) => {
  return {
    type: SET_COMMISSION_LOSS_REPORT,
    payload: data,
  };
};

export const setCommissionLossReportError = (error) => {
  return {
    type: SET_COMMISSION_LOSS_REPORT_ERROR,
    payload: error,
  };
};

export const clearCommissionLossReport = () => {
  return {
    type: CLEAR_COMMISSION_LOSS_REPORT,
  };
};

export const setCommissionLossReportLoading = (data) => {
  return {
    type: COMMISSION_LOSS_REPORT_LOADING,
    payload: data,
  };
};

export const fetchCommissionLossReport = (agencyId) => {
  return async (dispatch) => {
    dispatch(setCommissionLossReportLoading(true));
    try {
      let ProResultData = await getCLR(parseInt(agencyId));
      let ProResult = ProResultData.data.getCLR;
      dispatch(setCommissionLossReport(ProResult));
    } catch (error) {
      dispatch(setCommissionLossReportError(error));
    } finally {
      dispatch(setCommissionLossReportLoading(false));
    }
  };
};
