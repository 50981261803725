import React, { Fragment, useEffect, useState } from "react";
import { Grid, Typography, Paper } from "@mui/material";
import PieChartModelAgentInfo from "./PieChartModelAgentInfo";

// PieChartComponentAgentInfo is a component that displays a pie chart of agent contract statuses
const PieChartComponentAgentInfo = (props) => {
  const { agentTotal, contracts } = props;
  const [pieData, setPieData] = useState([]);

  useEffect(() => {
    const getValue = (id) => {
      return contracts.pieData.datasets[0].data[id].toString();
    };

    // getPieData returns an array of objects containing the id, value, and label of each slice of the pie
    const getPieData = () => {
      let index = 0;
      const results = [];
      if (contracts.pieData.labels.length > 0) {
        contracts.pieData.labels.forEach((item) => {
          results.push({ id: index, value: getValue(index), label: item });
          index += 1;
        });
      }
      return results;
    };
    setPieData(getPieData());
  }, [contracts]);

  return (
    <Fragment>
      <Typography variant="h5" component="h3" gutterBottom color="#003760">
        Agent Information
      </Typography>
      <Grid width={"auto"} height={600} gutterBottom>
        <Paper>
          <Typography style={{ padding: 10 }}>
            Total Number of Agents: {agentTotal}
          </Typography>
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              Agent Contract Status
            </Grid>
            <Grid container alignItems="center" justifyContent="center">
              <PieChartModelAgentInfo pieData={pieData} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Fragment>
  );
};

export default PieChartComponentAgentInfo;
