const CommissionLossReportData = (props) => {
  const { data } = props;
  const uniqueRows = [];

  data?.forEach((row) => {
    const newRow = {
      id: row.policy + row.writingNumber + row.planProduct + row.carrierName,
      agentName: row.agentName,
      writingNumber: row.writingNumber,
      upline: row.upline,
      memberName: row.memberName,
      effectiveDate: row.effectiveDate,
      dropDate: row.dropDate,
      planProduct: row.planProduct,
      policy: row.policy,
      hicn: row.hicn,
      carrierName: row.carrierName,
    };

    if (!uniqueRows.find((existingRow) => existingRow.id === newRow.id)) {
      uniqueRows.push(newRow);
    }
  });

  return uniqueRows;
};

export default CommissionLossReportData;
