import moment from "moment";

const commonProps = {
  headerAlign: "center",
  align: "center",
  headerClassName: "super-app-theme--header",
  cellClassName: "super-app-theme--cell",
  flex: 0,
};

const columns = [
  {
    field: "carrierName",
    headerName: "Carrier",
    width: 75,
    type: "singleSelect",
    valueOptions: ["UHC", "Aetna", "Humana"],
  },
  {
    field: "agentName",
    headerName: "Agent Name",
    width: 225,
    type: "string",
  },
  {
    field: "writingNumber",
    headerName: "Writing Number",
    width: 100,
    type: "number",
  },
  {
    field: "upline",
    headerName: "Parent Upline",
    width: 350,
    type: "string",
  },
  {
    field: "memberName",
    headerName: "Member",
    width: 225,
    type: "string",
  },
  {
    field: "effectiveDate",
      headerName: "Effective Date",
      width: 100,
      type: "date",
      valueFormatter: (params) => {
        const valueFormatted = new Date(Number(params.value));
        return moment(valueFormatted).format("L");
      },
  },
  {
    field: "dropDate",
    headerName: "Loss Date",
    width: 100,
    type: "string",
  },
  {
    field: "planProduct",
    headerName: "Plan Product",
    width: 100,
    type: "string",
  },
  {
    field: "policy",
    headerName: "Policy Number",
    width: 100,
    type: "string",
  },
  {
    field: "hicn",
    headerName: "HICN",
    width: 100,
    type: "string",
  },
];

const CommissionLossReportColumns = () => {
  return columns.map((column) => ({ ...commonProps, ...column }));
};

export default CommissionLossReportColumns;
