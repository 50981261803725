import React, { useState } from "react";
import Swal from "sweetalert2";
import {
  cognitoForgotPassword,
  cognitoConfirmPassword,
} from "../../services/GraphQl/BDSWebService";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
  ButtonGroup,
  Paper,
} from "@mui/material";
import { Box } from "@mui/system";
import { useHistory } from "react-router-dom";

const PasswordReset = (props) => {
  const mQuery = useMediaQuery("(max-width:600px)") ? true : false;
  const [isVerified, setIsVerified] = useState(false);
  const [verifyState, setVerifyState] = useState({
    username: "",
    verificationCode: "",
    newPass: "",
    poolKey: "admin",
  });
  const history = useHistory();
  const submitAndClose = async () => {
    try {
      await cognitoConfirmPassword(verifyState);
      Swal.fire({
        title: "Password Changed",
        icon: "success",
        text: "Now that you have reset your password, please login again with your new login credentials.",
        showCancelButton: false,
        confirmButtonText: "Let's Go!",
      }).then(() => {
        clearStatesAndClose();
      });
    } catch (err) {
      Swal.fire({
        title: "Oops!",
        icon: "error",
        text: "There was an issue with updating your password, please try re-entering the code or contact Berwick Agent Support at 888-745-2320 or agentsupport@berwickinusrance.com.",
      });
    }
  };

  const sendConfirmationCode = async () => {
    try {
      await cognitoForgotPassword({
        username: verifyState.username,
        poolKey: verifyState.poolKey,
      });
      Swal.fire({
        title: "Code Sent",
        icon: "info",
        text: "Please click confim below to go to the next step",
        showCancelButton: false,
        confirmButtonText: "Confirm",
      }).then(() => {
        setIsVerified(true);
      });
    } catch (err) {
      Swal.fire({
        titleText: err.message,
        icon: "error",
        html: "There was an issue with your request, please try again or contact Berwick Agent Support at 888-745-2320 or agentsupport@berwickinusrance.com.",
      });
    }
  };

  const clearStatesAndClose = () => {
    closePwResetDialog();
    setVerifyState({
      username: "",
      verificationCode: "",
      newPass: "",
      poolKey: "admin",
    });
    setIsVerified(false);
  };

  const { username, newPass, verificationCode } = verifyState;
  const checkPass = newPass.length >= 8;
  const verifyPassword = () => {
    return (
      checkPass &&
      /[!@#$%^&*(){}[\]|/\\:;"'<>,.?~`]/.test(newPass) &&
      /[a-z]/.test(newPass) &&
      /\d/.test(newPass) &&
      /[A-Z]/.test(newPass)
    );
  };

  const [showPassword, setShowPassword] = useState(false);
  const preventMouseDownPassword = (e) => {
    e.preventDefault();
  };
  const showPasswordClick = (e) => {
    setShowPassword(!showPassword);
  };

  const setState = (v, k) => {
    setVerifyState({ ...verifyState, [k]: v });
  };

  const closePwResetDialog = () => {
    history.push("/login");
  };

  const textFieldStyle = {
    "& .MuiOutlinedInput-root": {
      color: "#000",
      fontFamily: "Arial",
      fontWeight: "bold",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#2e2e2e",
        borderWidth: "2px",
      },
      "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#3c4143",
          borderWidth: "3px",
        },
      },
    },
    "& .MuiInputLabel-outlined": {
      color: "#2e2e2e",
      fontWeight: "bold",
      "&.Mui-focused": {
        color: "#3c4143",
        fontWeight: "bold",
      },
    },
  };

  const buttonStyle = {
    backgroundColor: "#3c4143",
    color: "#fff",
    "&:hover": { backgroundColor: "#616161" },
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      sx={{ height: "100vh" }}
    >
      <Grid item xs={4}>
        <Paper variant="elevation" elevation={1} sx={{ p: 5 }}>
          <Typography align="center" variant="h4" sx={{ m: "0 auto" }}>
            Reset Password
          </Typography>
          <Grid
            container
            style={{
              padding: "30px 20px",
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} align="center">
                <Typography variant="caption">
                  {!isVerified
                    ? "In order to reset your password please insert the information below."
                    : "Please enter the code and a new password below."}
                </Typography>
              </Grid>
              <Grid item xs={12} align="center" style={{ margin: 5 }}>
                <Typography variant="caption">
                  {!isVerified
                    ? `*Note: You will receive a code in your email or spam inbox.`
                    : `*Note: Passwords must be a length of 8 or greater and have at least one of EACH of the following: Capital letter | Lowercase Letter | Number |
          Special Character`}
                </Typography>
              </Grid>
              <Grid item xs={12} align="center" style={{ margin: 15 }}>
                {isVerified ? (
                  <>
                    <TextField
                      sx={textFieldStyle}
                      style={{ margin: 5 }}
                      variant="outlined"
                      name="verificationCode"
                      label="Verification Code"
                      value={verificationCode}
                      onChange={(e) =>
                        setState(e.target.value, "verificationCode")
                      }
                      fullWidth={mQuery}
                    />
                    <TextField
                      sx={textFieldStyle}
                      fullWidth={mQuery}
                      style={{ margin: 5 }}
                      variant="outlined"
                      id="password"
                      type={showPassword ? "text" : "password"}
                      value={newPass}
                      autoComplete="current-password"
                      onChange={(e) => setState(e.target.value, "newPass")}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={showPasswordClick}
                              onMouseDown={preventMouseDownPassword}
                              edge="end"
                              size="small"
                              style={{ color: "inherit" }}
                            >
                              {showPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      label="New Password"
                      error={checkPass && !verifyPassword()}
                      helperText={
                        checkPass ? (
                          verifyPassword() ? (
                            <Typography variant="subtitle2" component="span">
                              Valid Password
                            </Typography>
                          ) : (
                            "Invalid Password"
                          )
                        ) : (
                          ""
                        )
                      }
                    />
                  </>
                ) : (
                  <TextField
                    sx={textFieldStyle}
                    style={{ margin: 5 }}
                    variant="outlined"
                    fullWidth={mQuery}
                    label="Email"
                    type="email"
                    value={username}
                    onChange={(e) => setState(e.target.value, "username")}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <Grid item xs={6} alignItem="center">
              <ButtonGroup fullWidth>
                <Button
                  sx={{ ...buttonStyle, pr: 1 }}
                  variant="contained"
                  size="small"
                  disabled={
                    isVerified
                      ? !newPass || !verificationCode || !verifyPassword()
                      : !username
                  }
                  onClick={() =>
                    isVerified ? submitAndClose() : sendConfirmationCode()
                  }
                >
                  Submit
                </Button>
                <Button
                  sx={{ ...buttonStyle, pl: 1 }}
                  variant="contained"
                  size="small"
                  onClick={() => clearStatesAndClose()}
                >
                  Cancel
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default PasswordReset;
