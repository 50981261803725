import { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Ballot from "@mui/icons-material/Ballot";
import AllOut from "@mui/icons-material/AllOut";
import Agents from "../../agents/Agents";
import { TabPanel, TabContext } from "@mui/lab";
import ReadyToSell from "../../readyToSell/ReadyToSell";

const TabAgents = (props) => {
  const {
    filteredData,
    checkedAgencyGroupValue,
    checkedCarrierValues,
    checkedContractStatusValues,
    checkedLevelValues,
    agencyId,
    setTabCheck,
    agentsContractInfoData,
  } = props;
  const [value, setValue] = useState("one");

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setTabCheck(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
            aria-label="primary tabs example"
          >
            <Tab icon={<AllOut />} label="Contracts for Agents" value="one" />
            <Tab icon={<Ballot />} label="Ready to Sell" value="two" />
          </Tabs>
        </Box>
        <TabPanel value="one">
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
            }}
          >
            <Agents
              data={filteredData}
              checkedAgencyGroupValue={checkedAgencyGroupValue}
              checkedCarrierValues={checkedCarrierValues}
              checkedContractStatusValues={checkedContractStatusValues}
              checkedLevelValues={checkedLevelValues}
              agentsContractInfoData={agentsContractInfoData}
            />
          </div>
        </TabPanel>
        <TabPanel value="two">
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
            }}
          >
            <ReadyToSell agencyId={agencyId} />
          </div>
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default TabAgents;
