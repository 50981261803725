import React, { useMemo } from "react";
import { Button, ButtonGroup } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import AccountTree from "@mui/icons-material/AccountTree";
import classes from "./AgentsStyles";

const AgentsColumns = ({ handleUplineClick = () => {} }) => {
  const createColumn = (field, headerName, width, type, renderCell) => ({
    headerAlign: "center",
    align: "center",
    headerClassName: "super-app-theme--header",
    cellClassName: "super-app-theme--cell",
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    field,
    headerName,
    width,
    type,
    renderCell,
  });

  return useMemo(
    () => [
      createColumn("agentName", "Agent Name", 250, "string"),
      createColumn("agentEmail", "Email", 300, "string"),
      createColumn("agentPhone", "Phone Number", 200, "string"),
      createColumn("agentState", "State", 115, "string"),
      createColumn(
        "agentWayEnabled",
        "Registered with A-Way",
        115,
        null,
        (cellValues) => {
          return cellValues.value === "Yes" ? (
            <CheckCircleIcon style={{ color: "green" }} />
          ) : (
            <CancelIcon style={{ color: "red" }} />
          );
        }
      ),
      createColumn("agentId", "Agent ID", 200, "string"),
      createColumn("npn", "NPN", 200, "string"),
      createColumn("Action", "Action", 100, null, (cellValues) => {
        return (
          <ButtonGroup variant="contained">
            <Button
              className={`${classes.uplinesButton} ${classes.uplinesButtonHover} ${classes.uplinesButtonFocus}`}
              endIcon={<AccountTree />}
              onClick={(event) => handleUplineClick(event, cellValues)}
            >
              Uplines
            </Button>
          </ButtonGroup>
        );
      }),
    ],
    [handleUplineClick]
  );
};

export default AgentsColumns;
