import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import AgentPanel from "./AgentPanel";
import ContractPanel from "./ContractPanel";
import AgentChips from "./AgentChips";
import ContractChips from "./ContractChips";
import {
  sendContractRequestGql,
  isUserAuthenticated,
  agencyContractRequest,
  updateAgencyContractRequest,
} from "../../services/GraphQl/BDSWebService";
import * as Sentry from "@sentry/browser";
import Swal from "sweetalert";
import {
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  TextField,
  Grid,
  Zoom,
  Typography,
  Dialog,
} from "@mui/material";
import moment from "moment";
import { getAllCarriersMethod } from "../../store/actions/dashboard/contractForm";
import { connect } from "react-redux";
import { SET_CONTRACT_REQUESTS } from "../../store/constants/action-types";
import HtmlToolTip from "../common/HtmlTooltip";
const styles = (theme) => ({
  root: {
    "& label.Mui-focused": {
      color: "#3f51b5",
    },
    "& .MuiFilledInput-underline": {
      borderBottomColor: "#3f51b5",
    },
    "& .MuiInputLabel-root": {
      color: "#3f51b5",
    },
    "& .MuiFilledInput-root": {
      color: "#3f51b5",
    },
    "& .MuiInputBase-root": {
      color: "#3f51b5",
      backgroundColor: "#eee",
    },
    "& .MuiDialogTitle-root": {
      paddingTop: "5",
      paddingBottom: "5",
    },
  },
});

class ContractRequestModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agents: [],
      contracts: [],
      //Agent fields
      existingAgent: {},
      agentValidationErrors: {},
      //Contract fields
      existingContract: { isOther: false },
      contractValidationErrors: {},
      contractChipIndex: null,
      //additional fields
      notesValue: "",
      agentChipsShowing: false,
      contractCount: 0,
      requesterName: "",
      requesterEmail: "",
      requesterPhone: "",
      agentValidationMessage: "",
      contractValidationMessage: "",
      requesterEmailValidationMessage: "",
    };
  }
  handleAddAgent = () => {
    if (this.agentIsValid()) {
      const { existingAgent, agentChipIndex } = this.state;
      let { agents } = this.state;
      //if this is an update, remove the prior entry
      if (agentChipIndex !== null && agentChipIndex !== undefined) {
        this.handleAgentDelete(agentChipIndex);
      }

      if (existingAgent.dob === "Invalid date") {
        agents.push({ ...existingAgent, dob: null });
      } else {
        agents.push(existingAgent);
      }

      this.setState({
        agents,
      });

      //Clear Agent Panel
      this.resetAgentPanel();
    } else {
      Swal({
        title: "Oops!",
        text: "Please review the agent form for errors.",
        icon: "warning",
      });
    }
  };

  handleAgentClicked = (index) => {
    let selectedAgent = this.state.agents[index];
    const { existingAgent } = this.state;
    if (existingAgent.key != null) {
      Swal({
        title: "All unsaved changes will be lost!",
        text: "Click OK to discard the current information and load this agent.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((loadAgent) => {
        if (loadAgent) {
          this.fillExistingAgent(selectedAgent, index);
        }
      });
    } else {
      this.fillExistingAgent(selectedAgent, index);
    }
  };

  handleAgentDelete = (index) => {
    if (index === this.state.agentChipIndex) {
      this.removeAgentChip(index);
    } else {
      Swal({
        title: "Are you sure you want to remove this agent?",
        text: "Press OK to confirm.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((deleteAgent) => {
        if (deleteAgent) {
          this.removeAgentChip(index);
        }
      });
    }
  };

  removeAgentChip = (index) => {
    let newAgentArray = this.state.agents;
    newAgentArray.splice(index, 1);

    this.setState({
      agents: newAgentArray,
    });

    if (this.state.agentChipIndex === index) {
      this.resetAgentPanel();
    }
  };

  handleContractClicked = (index) => {
    var { existingContract, contracts } = this.state;
    let selectedContract = contracts[index];
    if (Object.keys(existingContract).length > 1) {
      Swal({
        title: "All unsaved changes will be lost!",
        text: "Click OK to discard the current information and load this contract.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((loadContract) => {
        if (loadContract) {
          this.loadContract(selectedContract, index);
        }
      });
    } else {
      this.loadContract(selectedContract, index);
    }
  };

  loadContract = (selectedContract, index) => {
    this.setState({
      existingContract: selectedContract,
      contractChipIndex: index,
    });
  };

  handleContractDelete = (index) => {
    if (index === this.state.contractChipIndex) {
      this.removeContractChip(index);
    } else {
      Swal({
        title: "Are you sure you want to remove this contract?",
        text: "Press OK to confirm.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((deleteContract) => {
        if (deleteContract) {
          this.removeContractChip(index);
        }
      });
    }
  };

  removeContractChip = (index) => {
    let newContractArray = this.state.contracts;
    newContractArray.splice(index, 1);

    this.setState({
      contracts: newContractArray,
    });

    if (this.state.contractChipIndex === index) {
      this.resetContractPanel();
    }
  };

  resetAgentPanel = () => {
    this.setState({
      agentValidationErrors: {},
      existingAgent: {},
      agentChipIndex: null,
    });
  };

  resetContractPanel = () => {
    this.setState({
      existingContract: { isOther: false },
      contractValidationErrors: {},
      contractChipIndex: null,
    });
  };

  isValidEmail = (email) => {
    if (
      /^[a-zA-Z0-9$&+,:;=?@#|'_.-^*%!.]+@[a-zA-Z0-9._+-]+\.[A-Za-z]+$/.test(
        email
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  agentIsValid = () => {
    let { agentValidationErrors, existingAgent } = this.state;
    var newValidationObj = { ...agentValidationErrors };
    if (!existingAgent.fName || existingAgent.fName.length <= 1) {
      let msg = "First name cant be empty or short";
      newValidationObj.fName = msg;
    } else {
      newValidationObj.fName = undefined;
    }

    if (!existingAgent.lName || existingAgent.lName.length <= 1) {
      let msg = "Last name cant be empty or short";
      newValidationObj.lName = msg;
    } else {
      newValidationObj.lName = undefined;
    }
    if (!existingAgent.phone || existingAgent.phone.length !== 10) {
      let msg = "Please enter a 10 digit phone number.";
      newValidationObj.phone = msg;
    } else {
      newValidationObj.phone = undefined;
    }
    if (
      !existingAgent.stateAbbreviation ||
      existingAgent.stateAbbreviation === null
    ) {
      let msg = "Please select the state.";
      newValidationObj.stateAbbreviation = msg;
    } else {
      newValidationObj.stateAbbreviation = undefined;
    }
    if (!this.isValidEmail(existingAgent.email)) {
      let msg = "Please enter a valid email address.";
      newValidationObj.email = msg;
    } else {
      newValidationObj.email = undefined;
    }
    if (existingAgent.ContractingAsCorp === true) {
      if (!existingAgent.corpName) {
        let msg = "Please enter the Corp Name.";
        newValidationObj.corpName = msg;
      } else {
        newValidationObj.corpName = undefined;
      }
      if (!existingAgent.corpNPN) {
        let msg = "Please enter the Corp NPN.";
        newValidationObj.corpNPN = msg;
      } else {
        newValidationObj.corpNPN = undefined;
      }
    } else {
      newValidationObj.corpName = undefined;
      newValidationObj.corpNPN = undefined;
    }
    this.setState({
      agentValidationErrors: newValidationObj,
    });

    return !Object.values(newValidationObj).some((item) => item !== undefined);
  };

  contractIsValid = () => {
    var { existingContract, contractValidationErrors } = this.state;
    var newValidationObj = { ...contractValidationErrors };
    if (!existingContract.carrier) {
      newValidationObj.carrier = "Please select a carrier for this contract.";
    } else {
      newValidationObj.carrier = undefined;
    }
    if (!existingContract.requestLevel) {
      newValidationObj.requestLevel = "Please enter a level for this contract.";
    } else {
      newValidationObj.requestLevel = undefined;
    }
    if (!existingContract.upline) {
      newValidationObj.upline = "Please enter an upline for this contract.";
    } else {
      newValidationObj.upline = undefined;
    }
    if (!existingContract.statesRequested) {
      newValidationObj.statesRequested =
        "Please enter states requested for this contract.";
    } else {
      newValidationObj.statesRequested = undefined;
    }
    this.setState({
      contractValidationErrors: newValidationObj,
    });

    return !Object.values(newValidationObj).some((item) => item !== undefined);
  };

  handleAddContract = () => {
    const { contracts, existingContract, contractChipIndex } = this.state;
    if (this.contractIsValid()) {
      if (contractChipIndex !== null) {
        this.handleContractDelete(contractChipIndex);
      }
      contracts.push(existingContract);
      this.setState({
        contracts,
      });

      if (existingContract.carrier === "SCAN") {
        Swal({
          title: "SCAN requires license numbers for each state requested.",
          text: "If requesting more than 1 state (resident state) please indicate the license numbers and states in the notes section below.",
          icon: "info",
        });
      }

      //Clear Contract Panel
      this.resetContractPanel();
    } else {
      Swal({
        title: "Oops!",
        text: "Please review the contract form for errors.",
        icon: "warning",
      });
    }
    //else contract invalid
  };

  handleAgentInputChange = (event) => {
    var { existingAgent } = this.state;
    const target = event.target;
    const value =
      target.type === "checkbox" || target.type === "switch"
        ? target.checked
        : target.value;
    const name = target.name;
    if (name === "ContractingAsCorp" && value === false) {
      existingAgent.corpName = undefined;
      existingAgent.corpNPN = undefined;
      existingAgent.corpTIN = undefined;
    }
    this.setState({
      existingAgent: { ...existingAgent, [name]: value },
    });
  };
  handleInputChange = (event) => {
    const target = event.target;
    let value;
    if (target.type === "checkbox" || target.type === "switch") {
      value = target.checked;
    } else if (target.type === "date" && target.value !== "Invalid date") {
      value = moment(target.value).format("L");
    } else {
      value = target.value;
    }

    const name = target.name;
    this.setState({
      [name]: value,
    });
  };
  handleContractInputChange = (event) => {
    var { existingContract } = this.state;
    const target = event.target;
    const value =
      target.type === "checkbox" || target.type === "switch"
        ? target.checked
        : target.value;
    const name = target.name;

    this.setState({
      existingContract: { ...existingContract, [name]: value },
    });
  };

  confirmSaveOrAbandonContract = async (event) => {
    let _title = "Save this contract?";
    let _text =
      "Looks like there is data in the contract area, but you didn't click 'Add Contract'.  Click OK to save this contract and submit, or cancel to continue editing.";

    Swal({
      title: _title,
      text: _text,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (saveContract) => {
      if (saveContract) {
        if (this.contractIsValid()) {
          this.handleAddContract();
          await this.handleSubmit(event);
        } else {
          Swal({
            title: "Oops!",
            text: "Please review the contract form for errors.",
            icon: "warning",
          });
        }
      } else {
        //allow user to continue editing the form
      }
    });
  };

  handleExistingAgentSelectionChange = (selectedItem) => {
    let { existingAgent } = this.state;
    if (existingAgent.key != null) {
      Swal({
        title: "All unsaved changes will be lost!",
        text: "Click OK to discard the current information and load this agent.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((loadAgent) => {
        if (loadAgent) {
          this.fillExistingAgent(selectedItem);
        }
      });
    } else {
      this.fillExistingAgent(selectedItem);
    }
  };

  resetexistingAgent = () => {
    this.setState({
      existingAgent: {},
    });
  };
  fillExistingAgent(selectedItem, agentChipIndex) {
    if (selectedItem.phone) {
      selectedItem.phone = selectedItem.phone.replace(/[^0-9]/g, "");
    }
    this.setState({
      agentChipIndex: agentChipIndex,
      existingAgent: selectedItem,
      validationErrors: {},
    });
  }

  confirmSaveOrAbandonAgent = async (event) => {
    let _title = "Save this agent?";
    let _text =
      "Looks like there is data in the agent area, but you didn't click 'Add Agent'.  Click OK to save this agent and submit, or cancel to continue editing.";

    Swal({
      title: _title,
      text: _text,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (saveAgent) => {
      if (saveAgent) {
        if (this.agentIsValid()) {
          this.handleAddAgent();
          await this.handleSubmit();
        } else {
          Swal({
            title: "Oops!",
            text: "Please review the agent form for errors.",
            icon: "warning",
          });
        }
      }
    });
  };

  handleSubmit = async (event) => {
    let invalid = false;
    let sweetAlertMessage = "";
    let agentCheck = false;
    let contractCheck = false;
    const {
      requesterEmail,
      requesterName,
      requesterPhone,
      existingAgent,
      existingContract,
      agents,
      contracts,
      notesValue,
    } = this.state;

    if (existingAgent.key != null) {
      invalid = true;
      sweetAlertMessage =
        "Did you intend to save the changes to the agent in the form?";
      agentCheck = true;
    }

    if (requesterName === "") {
      invalid = true;
      sweetAlertMessage = "Please enter a Contact Name";
    } else if (
      requesterEmail === "" ||
      requesterEmail === null ||
      !this.isValidEmail(requesterEmail)
    ) {
      invalid = true;
      sweetAlertMessage = "Please enter a valid Contact Email";
    }

    if (
      requesterPhone === "" ||
      requesterPhone === null ||
      requesterPhone.length < 10
    ) {
      invalid = true;
      sweetAlertMessage = "Please enter a valid phone number";
    }

    if (Object.keys(existingContract).length > 1) {
      invalid = true;
      sweetAlertMessage =
        "Did you intend to save the changes to the contract in the form?";
      contractCheck = true;
    }

    if (agents.length === 0) {
      invalid = true;
      sweetAlertMessage =
        "At least one agent is required!  Please add an agent.";
    }

    if (contracts.length === 0) {
      invalid = true;
      sweetAlertMessage =
        "At least one contract is required!  Please add a contract.";
    }

    const onlyWelbeContracts = contracts.every(function (contract) {
      return contract.carrier.toString().toLowerCase().includes("welbe");
    });

    if (!onlyWelbeContracts) {
      //NPN is required for every agent if any contract is for a carrier other than Welbe.
      //In other words, NPN is only NOT required when Welbe is the only contract carrier.
      agents.every(function (agent) {
        if (agent.npn === undefined || agent.npn === "") {
          sweetAlertMessage =
            "NPN is required for every agent unless Welbe is the only contract carrier.";
          invalid = true;
          return false;
        }
        return true;
      });
    }

    const anyWelbeContracts = contracts.some(function (contract) {
      return contract.carrier.toString().toLowerCase().includes("welbe");
    });

    if (anyWelbeContracts) {
      //Welbe requires DOB for all agents.  If a DOB is missing, alert the user.
      agents.every(function (agent) {
        if (!agent.dob) {
          sweetAlertMessage = "Welbe contracts require DOB for all agents.";
          invalid = true;
          return false;
        }
        return true;
      });
    }

    const anyFloridaBlueContracts = contracts.some(function (contract) {
      return contract.carrier.toString().toLowerCase().includes("florida blue");
    });

    if (anyFloridaBlueContracts) {
      //Florida Blue requires each agent to be gendered.  If an agent is not gendered, alert the user.
      agents.every(function (agent) {
        if (!agent.gender || !agent.gender === null) {
          sweetAlertMessage =
            "Florida Blue requires gender information for all agents.";
          invalid = true;
          return false;
        }
        if (agent.socialNumber === undefined || agent.socialNumber.length < 9) {
          sweetAlertMessage =
            "Florida Blue requires a 9 digit social security number for every agent.  Please review your agent data.";
          invalid = true;
          return false;
        }
        if (!agent.dob) {
          sweetAlertMessage =
            "Florida Blue contracts require DOB for all agents.";
          invalid = true;
          return false;
        }
        return true;
      });
    }

    if (!invalid) {
      //format DOB so that it looks right in the email
      agents.forEach((agent) => {
        if (agent.dob) {
          agent.dob = moment(agent.dob, "YYYY-MM-DD").format("MM-DD-YYYY");
        }
      });
    }

    //Anthem requirements validation begin
    let anthemContractFound = contracts.some(function (contract) {
      return contract.carrier.toString().toLowerCase().includes("anthem");
    });

    if (anthemContractFound) {
      agents.every(function (agent) {
        if (agent.socialNumber === undefined || agent.socialNumber.length < 9) {
          sweetAlertMessage =
            "Anthem requires a 9 digit social security number for every agent.  Please review your agent data.";
          invalid = true;
          return false;
        }
        if (
          agent.ContractingAsCorp === true &&
          agent.ContractingAsCorp !== undefined &&
          (agent.corpTIN === undefined ||
            agent.corpTIN === null ||
            agent.corpTIN.length < 5)
        ) {
          sweetAlertMessage = "Anthem requires a 5 digit minimum TIN number.";
          invalid = true;
          return false;
        }
        return true;
      });
    }

    // Anthem requirements validation end

    const gotScanContracts = contracts.some(
      (contract) => contract.carrier === "SCAN"
    );
    if (gotScanContracts) {
      const result = await Swal({
        title: "SCAN requires license numbers for each state requested.",
        text: "If requesting more than 1 state (resident state) please indicate the license numbers and states in the notes section below.",
        icon: "info",
        buttons: true,
      });
      if (!result) {
        this.resetContractPanel();
        invalid = true;
        sweetAlertMessage = "Add additional license numbers in notes section.";
      }
    }

    if (
      contracts.some((contract) => contract.carrier === "SCAN") &&
      agents.some((agent) => {
        return agent.license === undefined || agent.license === "";
      })
    ) {
      sweetAlertMessage =
        "SCAN requires a license number for every agent.  Please review your agent data.";
      invalid = true;
    }

    if (!invalid) {
      let emailResult;
      for (const agent in agents) {
        delete agents[agent].key;
        delete agents[agent].value;

        //assign empty string if license is undefined
        agents[agent].license = agents[agent].license
          ? agents[agent].license
          : "";
      }
      for (const contract in contracts) {
        delete contracts[contract].isOther;
      }
      const isStaging = sessionStorage.getItem("isStaging") === "true";
      var input = {
        agencyName: sessionStorage.getItem("agencyName"),
        agents: agents,
        contracts: contracts,
        notes: notesValue,
        requesterName: requesterName,
        requesterEmail: requesterEmail,
        requesterPhone: requesterPhone,
        isStaging: isStaging,
      };
      const userCurrentlyAuthed = await isUserAuthenticated();

      if (userCurrentlyAuthed) {
        emailResult = await sendContractRequestGql(input);
        if (emailResult.error) {
          Sentry.captureMessage(
            "email error: " +
              emailResult.error +
              " message:" +
              emailResult.message
          );
          console.log(
            "email error: " +
              emailResult.error +
              " message:" +
              emailResult.message
          );
        }
        console.log("email result: " + emailResult.data.toString());
        const { rowData, storedContractRequests, setContractRequests } =
          this.props;
        const agencyId = sessionStorage.getItem("agencyId");
        if (rowData) {
          const { id, agentId, carrierName } = rowData;
          const { upline, requestLevel } = input.contracts[0];
          updateAgencyContractRequest({
            id: id,
            uplineRequested: upline,
            agentLevelRequested: requestLevel,
          });
          agencyContractRequest({
            id,
            status: "submitted",
            subject: `Contract Request Submitted for ${carrierName}`,
            body: `${carrierName} has been successfully submitted to processing`,
            agencyId,
            agentIds: [agentId],
            type: "Contract Request Submitted",
          }).then(() => {
            const result = storedContractRequests.filter(
              (row) => row.id !== id
            );
            setContractRequests(result);
          });
        }
      }

      if (emailResult.data.sendContractRequestV2 === true) {
        Swal({
          title: "Contract requests submitted to Berwick",
          icon: "success",
        }).then(this.handleCloseAll());
      } else {
        Swal({
          title: "Failed to send.  Please try again.",
          icon: "error",
        }).then(
          Sentry.captureMessage(
            "CONTRACT REQUEST EMAIL FAILURE. " +
              emailResult.toString() +
              " Please contact " +
              requesterName +
              " at " +
              requesterPhone +
              " or " +
              requesterEmail +
              " to follow up.",
            Sentry.Severity.Error
          )
        );
      }
    } else {
      if (agentCheck) {
        this.confirmSaveOrAbandonAgent();
      } else if (contractCheck) {
        this.confirmSaveOrAbandonContract();
      } else {
        Swal({
          title: "Oops!",
          text: sweetAlertMessage,
          icon: "error",
        });
      }
    }
  };

  handleCloseAll = () => {
    this.resetAgentPanel();
    this.resetContractPanel();
    this.setState({
      agents: [],
      contracts: [],
      notesValue: "",
    });
    this.props.handleClose("contractModalOpen");
  };

  componentDidMount() {
    this.props.setCarriers();
    const { rowData, carrierOptionsData } = this.props;
    if (rowData) {
      const {
        agentName,
        birthDate,
        carrierName,
        states,
        phone,
        gender,
        residentState,
        email,
        npn,
        licenseNumber,
        ssn,
        isAsCorp,
        corpName,
        corpNpn,
        corpTin,
      } = rowData;
      const fName = agentName.split(" ")[0].trim();
      const lName = agentName.split(" ")[1].trim();
      this.setState({
        existingAgent: {
          key: agentName,
          fName,
          lName,
          phone,
          gender,
          email,
          npn,
          license: licenseNumber,
          ContractingAsCorp: isAsCorp,
          stateAbbreviation: residentState,
          socialNumber: ssn,
          corpName,
          corpNPN: corpNpn,
          corpTIN: corpTin,
          dob: moment(birthDate).format("YYYY-MM-DD"),
        },
        existingContract: {
          carrier: carrierName,
          statesRequested: states,
          isOther: !carrierOptionsData.some(({ name }) => name === carrierName),
        },
        notesValue: rowData.notes,
      });
    }
  }

  render() {
    const {
      classes,
      handleClose,
      contractModalOpen,
      rowData,
      carrierOptionsData,
    } = this.props;
    const {
      agentValidationErrors,
      existingAgent,
      agents,
      existingContract,
      contractValidationErrors,
      contracts,
      notesValue,
      contractChipIndex,
    } = this.state;
    return (
      <div>
        <Dialog
          open={contractModalOpen}
          onClose={() => handleClose("contractModalOpen")}
          scroll={"paper"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          style={{ borderRadius: 8 }}
          disablebackdropclick
          disableEscapeKeyDown
        >
          <DialogTitle
            id="scroll-dialog-title"
            align="center"
            style={{
              backgroundColor: "#444242",
              color: "#eee",
              borderTopLeftRadius: 3,
              borderTopRightRadius: 3,
            }}
          >
            New Downline Contract Request
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
              }}
              noValidate
              // inputprops={{
              //   inputProps: { autocomplete: "new-password" },
              // }}
            >
              {agents.length > 0 ? (
                <AgentChips
                  agents={agents}
                  handleAgentClicked={this.handleAgentClicked}
                  handleAgentDelete={this.handleAgentDelete}
                  rowData={rowData}
                />
              ) : null}

              <AgentPanel
                agentValidationErrors={agentValidationErrors}
                existingAgent={existingAgent}
                handleAddAgent={this.handleAddAgent}
                handleInputChange={this.handleAgentInputChange}
                handleisAsCorpChange={this.handleisAsCorpChange}
                agentChipIndex={this.state.agentChipIndex}
                resetAgentPanel={this.resetAgentPanel}
                resetexistingAgent={this.resetexistingAgent}
                existingAgentSelectionChange={
                  this.handleExistingAgentSelectionChange
                }
                rowData={rowData}
                agentsLength={agents.length}
              />

              <DialogContentText
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  color: "black",
                  width: "100%",
                  fontWeight: "bold",
                }}
              >
                <br></br>
              </DialogContentText>

              {contracts.length > 0 ? (
                <ContractChips
                  contracts={contracts}
                  handleContractClicked={this.handleContractClicked}
                  handleContractDelete={this.handleContractDelete}
                  rowData={rowData}
                />
              ) : null}

              <ContractPanel
                existingContract={existingContract}
                contractValidationErrors={contractValidationErrors}
                contractChipIndex={contractChipIndex}
                handleInputChange={this.handleContractInputChange}
                handleAddContract={this.handleAddContract}
                resetContractPanel={this.resetContractPanel}
                contractsLength={contracts.length}
                rowData={rowData}
                carrierMap={carrierOptionsData}
              />
              <Grid
                container
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
              >
                <Grid
                  item
                  style={{
                    paddingTop: 0,
                    paddingBottom: 0,
                  }}
                >
                  <Grid container alignItems="baseline">
                    <Grid item>
                      <Typography
                        variant="subtitle1"
                        style={{
                          marginTop: "2em",
                          fontWeight: 700,
                          fontStyle: "bold",
                        }}
                      >
                        Your Contact Information
                      </Typography>
                    </Grid>
                    <Grid item>
                      <span
                        style={{
                          color: "red",
                          fontStyle: "italic",
                          fontSize: "smaller",
                          fontWeight: "bold",
                          textAlign: "right",
                        }}
                      >
                        (Confirmation email will be sent to the address provided
                        here)
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  justifySelf="center"
                  item
                  container
                  xs={12}
                  style={{ border: "1px solid #000" }}
                >
                  <Grid item container>
                    <Grid
                      item
                      xs={6}
                      style={{
                        padding: "0 2.5px 0 5px",
                      }}
                    >
                      <HtmlToolTip
                        title="Full name of the person filling in this form"
                        placement="top"
                        interactive
                        TransitionComponent={Zoom}
                      >
                        <TextField
                          id="filled-requesterName-input"
                          fullWidth
                          required
                          margin="dense"
                          value={this.requesterName}
                          variant="filled"
                          classes={{ root: classes.root }}
                          name="requesterName"
                          label="Your Name"
                          onChange={this.handleInputChange}
                          inputProps={{
                            inputProps: { autocomplete: "requesterName" },
                          }}
                        />
                      </HtmlToolTip>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{
                        padding: "0 5px 0 5px",
                      }}
                    >
                      <HtmlToolTip
                        title="Phone number of the person filling in this form"
                        placement="top"
                        interactive
                        TransitionComponent={Zoom}
                      >
                        <TextField
                          id="filled-requesterPhone-input"
                          onInput={(e) => {
                            let numericValue = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                            if (numericValue !== "") {
                              numericValue = Math.max(0, parseInt(numericValue))
                                .toString()
                                .slice(0, 10);
                              e.target.value =
                                numericValue === "NaN" ? null : numericValue;
                            } else {
                              e.target.value = null;
                            }
                          }}
                          fullWidth
                          required
                          value={this.requesterPhone}
                          margin="dense"
                          variant="filled"
                          classes={{ root: classes.root }}
                          name="requesterPhone"
                          label="Your Phone #"
                          inputProps={{
                            inputProps: { autocomplete: "requesterPhone" },
                          }}
                          onChange={this.handleInputChange}
                        />
                      </HtmlToolTip>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        padding: "0 5px 0 5px",
                      }}
                    >
                      <HtmlToolTip
                        title="Email of the person filling in this form"
                        placement="top"
                        interactive
                        TransitionComponent={Zoom}
                      >
                        <TextField
                          id="filled-requesterEmail-input"
                          fullWidth
                          required
                          value={this.requesterEmail}
                          margin="dense"
                          variant="filled"
                          classes={{ root: classes.root }}
                          name="requesterEmail"
                          label="Your Email"
                          inputProps={{
                            inputProps: { autocomplete: "requesterEmail" },
                          }}
                          onChange={this.handleInputChange}
                        />
                      </HtmlToolTip>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
              >
                <Grid
                  item
                  xs={12}
                  style={{
                    paddingTop: 0,
                    paddingBottom: 0,
                  }}
                >
                  <TextField
                    multiline
                    id="filled-notes-input"
                    label="Notes"
                    name="notesValue"
                    rows="3"
                    fullWidth
                    margin="dense"
                    variant="filled"
                    classes={{ root: classes.root }}
                    value={notesValue}
                    onChange={this.handleInputChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions
            style={{
              backgroundColor: "#444242",
              padding: 22,
              borderBottomLeftRadius: 3,
              borderBottomRightRadius: 3,
            }}
          >
            <Button
              onClick={this.handleSubmit}
              style={{ color: "#eee", backgroundColor: "#3f51b5" }}
              fullWidth
            >
              Submit Contract Request
            </Button>
            <Button
              onClick={() => this.handleCloseAll()}
              style={{ backgroundColor: "#fff176", width: 200 }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCarriers: () => dispatch(getAllCarriersMethod()),
    setContractRequests: (result) =>
      dispatch({ type: SET_CONTRACT_REQUESTS, payload: result }),
  };
};

const mapStateToProps = (state) => {
  return {
    carrierOptionsData: state.contractFormData,
    storedContractRequests:
      state.currentContractRequests.storedContractRequests,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ContractRequestModal));
