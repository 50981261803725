import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchCommissionLossReport } from "../../store/actions/production/commissionLossReport";
import DataGridTemplate from "../DataGridTemplate/DataGridTemplate";
import classes from "../DataGridTemplate/DataGridTemplate.module.css"; 
import CommissionLossReportColumns from "./CommissionLossReportColumns";
import CommissionLossReportData from "./CommissionLossReportData";

const CommissionLossReports = () => {
  const dispatch = useDispatch();
  const { commissionLossReportData, commissionLossReportLoading } = useSelector(
    (state) => state.commissionLossReport
  );
  useEffect(() => {
    const agencyId = sessionStorage.getItem("agencyId");
    const fetchData = async () => {
      if (
        commissionLossReportData === undefined ||
        commissionLossReportData.length === 0
      ) {
        dispatch(fetchCommissionLossReport(parseInt(agencyId)));
      }
    };
    fetchData();
  }, [dispatch, commissionLossReportData]);

  const columns = CommissionLossReportColumns();
  const uniqueRows = CommissionLossReportData({
    data: commissionLossReportData,
  });

  /// Return the DataGrid
  return (
    <div className={classes.myClass}>
      <DataGridTemplate
        columns={columns}
        uniqueRows={uniqueRows.sort((a, b) =>
          a.agentName.localeCompare(b.agentName)
        )}
        isLoading={commissionLossReportLoading}
        paginationProps={{ rowsPerPageOptions: [25, 50, 100], pageSize: 25 }}
        CustomToolbar="All"
        density="standard"
        size="medium"
        type=""
        autoHeight={false}
      />
    </div>
  );
};

export default CommissionLossReports;
